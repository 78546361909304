import { useEffect, useState } from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import { useWindowSize } from 'react-use';
import is from 'is_js';

const Roadblock = () => {
	const { width, height } = useWindowSize();
	const [isLandscape, setIsLandscape] = useState(false);
	const isPhone = is.mobile();
	const isPortrait = height < width;

	useEffect(() => {
		if (isPhone && isPortrait && width < 1100) {
			setIsLandscape(true);
		} else {
			setIsLandscape(false);
		}
	}, [isPhone, isPortrait, width, height]);

	return (
		<div
			className={`ROADBLOCK fixed inset-0 p-8 z-[100] bg-blue_bg flex items-center justify-center font-[800] text-white ${
				isLandscape ? 'block' : 'hidden'
			}`}
		>
			<div className='absolute inset-0 opacity-25 bg-dotted-spacing-4 bg-dotted-blue_bar mix-blend-hard-light' />

			<div className='flex items-center justify-center space-x-2 text-lg'>
				<FaMobileAlt className='text-6xl' />
				<span>Please rotate your device to continue</span>
			</div>
		</div>
	);
};

export default Roadblock;
