import Roadblock from '@components/Roadblock';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
// import analytics from '@lib/analytics';


import data from '@data/index';

export default function Layout({ children }) {
	const [isReady, setIsReady] = useState(null);
	const { meta } = data.global;
	const { t } = useTranslation('common');

	useEffect(() => {
		//analytics.track('site loaded');

		setIsReady(true);
	}, []);

	return (
		<div className={`LAYOUT`}>

			<NextSeo
				title={t(meta.title)}
				description={t(meta.description)}
				openGraph={{
					type: 'website',
					title: meta.title,
					description: t(meta.description),
					images: [
						{
							url: meta.image,
							width: 1200,
							height: 630,
							alt: '',
						},
					],
					siteName: meta.title,
				}}
				twitter={{
					cardType: 'summary_large_image',
				}}
				additionalMetaTags={[
					{
						name: 'viewport',
						content:
							'width=device-width, user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover',
					},
					{
						name: 'theme-color',
						content: '#000000',
					},
				]}
				additionalLinkTags={[
					{
						rel: 'icon',
						href: `/favicon-32x32.png`,
						sizes: '32x32',
					},
					{
						rel: 'apple-touch-icon',
						href: `/favicon-16x16.png`,
						sizes: '16x16',
					},
					{
						rel: 'preload',
						href: '/assets/images/logo.svg',
						as: 'image',
					},
					{
						rel: 'preload',
						href: '/assets/videos/hero.jpg',
						as: 'image',
					},
					{
						rel: 'preload',
						href: '/assets/videos/hero_m.jpg',
						as: 'image',
					},
					{
						rel: 'preload',
						href: '/assets/fonts/TradeGothicLTStd-Bold.woff2',
						as: 'font',
						crossOrigin: 'anonymous',
					},
					{
						rel: 'preload',
						href: '/assets/fonts/TradeGothicLTStd-Cn18.woff2',
						as: 'font',
						crossOrigin: 'anonymous',
					},
					{
						rel: 'preload',
						href: '/assets/fonts/TradeGothicLTStd.woff2',
						as: 'font',
						crossOrigin: 'anonymous',
					},
					{
						rel: 'preload',
						href: '/assets/fonts/TradeGothicNextLTPro-HvCn.woff2',
						as: 'font',
						crossOrigin: 'anonymous',
					},
					{
						rel: 'preload',
						href: '/assets/fonts/TradeGothicNextLTPro-HvCn.woff',
						as: 'font',
						crossOrigin: 'anonymous',
					},
				]}
			/>
			{/* <Header /> */}

			{children}

			<Roadblock />
			
		</div>
	);
}
