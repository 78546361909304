export const data = {
	global: {
		meta: {
			title: 'YES Network',
			description: `Comprehensive coverage of the New York Yankees, Brooklyn Nets, Major League Baseball (MLB), National Basketball Association(NBA), New York Sports, and Basketball`,
			image: '/assets/images/share.jpg',
		},
		logo: {
			src: '/assets/images/logo.svg',
			width: 0,
			height: 0,
			label: 'YES',
		},
		logo_dark: {
			src: '/assets/images/logo-dark.svg',
			width: 0,
			height: 0,
			label: 'YES',
		},
		offer: [
			'STREAM MORE FOR LESS WHEN YOU ACT NOW.<br class="block sm:hidden" /> INTRODUCTORY OFFER ENDS APRIL 30.',
			'Offer expires in',
		],
		countdown: {
			date: '2023-04-30',
			time: '23:59:59',
			timezone: 'America/New_York',
			tooltip: 'April 30th @ Midnite (EST)',
		},
		cta: {
			id: 'subscribe',
			label: 'SUBSCRIBE NOW',
			link: 'https://watchyesnetwork.com/register?source=knownlp',
		},
		links: {
			yesNetwork: 'https://www.yesnetwork.com/',
			login: 'https://watchyesnetwork.com/login',
			watch: 'https://watchyesnetwork.com/watch',
			providerLogin: 'https://watchyesnetwork.com/watch',
			subscribe: 'https://watchyesnetwork.com/register?source=knownlp',
			helpCenter: 'https://yesnetworkhelp.zendesk.com/hc/en-us',
			terms: 'https://www.yesnetwork.com/info/terms-of-service',
			privacy: 'https://www.yesnetwork.com/info/privacy-policy',
		},
		copyright: '2024 YES NETWORK. ALL RIGHTS RESERVED. YESNetwork.com: Yankees Entertainment & Sports Network',
		notFound: 'The page you are looking for wasn&apos;t found.',
	},
	main: {
		// title: `STREAM <span class='!text-blue_bar'>YES NETWORK</span> 24<span class='mx-2'>/</span>7`,
		// title_m: `STREAM <span class='!text-blue_bar'>YES<br />NETWORK</span> 24<span class='mx-2'>/</span>7`,
		subtitle: 'Yankees and Nets, live on all your devices',
		video: 'https://d3lae9h8l9j5el.cloudfront.net/LP030724Desktop.mp4',
		video_poster: '/assets/videos/hero.jpg',
		video_m: 'https://d3lae9h8l9j5el.cloudfront.net/LP030724Phone.mp4',
		video_m_poster: '/assets/videos/hero_m.jpg',

		section_title: "<span class='text-white'>The Best Way</span> <br /><span class='text-blue_bar'>To Watch</span>",
		section_subtitle: 'The most enhanced viewing experience with in-depth stats and analytics overlays',
		bgImage: {
			src: '/assets/images/stripes.png',
			width: 949,
			height: 587,
		},
		image_app: {
			src: '/assets/images/main/app.png',
			width: 779,
			height: 565,
		},
		offerings: [
			{
				id: 'annual',
				title: 'Annual Plan',
				price: '$239.99',
				_price_sale: '$239.99',
				saleTagline: 'Best Value',
				installments: 'Pay in installments with 0% interest',
				regularPrice: 'Regular Price:',
			},
			{ id: 'monthly', title: 'Monthly Plan', _price_sale: '$19.99', price: ' $24.99', regularPrice: 'Regular Price:' },
		],
		//blurb: '*OFFER EXPIRES APRIL 30, 2024',
		terms: 'INTRODUCTORY OFFER. SUBJECT TO TERRITORIAL RESTRICTIONS.',
		logos: [
			{
				id: 'yankees',
				src: '/assets/images/logos/yankees.svg',
				width: 0,
				height: 0,
				label: 'Yankees',
			},
			{
				id: 'nets',
				src: '/assets/images/logos/nets.svg',
				width: 0,
				height: 0,
				label: 'Nets',
			},
			// {
			// 	id: 'liberty',
			// 	src: '/assets/images/logos/liberty.svg',
			// 	width: 0,
			// 	height: 0,
			// 	label: 'Liberty',
			// },
		],
		cta: {
			id: 'subscribe',
			label: 'SUBSCRIBE NOW',
		},
		cta1: {
			id: 'watch',
			prelabel: 'Already a subscriber or have YES Network via your Cable Provider?',
			label: 'Login Now',
		},
		cta2: {
			id: 'providerLogin',
			label: 'Login with TV Provider',
		},
		cta3: {
			id: 'terms',
			label: '<sup>*</sup>Click here for full terms & conditions',
		},
		cta4: {
			id: 'terms',
			label: 'YES Network is also available from select cable TV providers',
		},
	},

	reasons: {
		title: '',
		// title: `<span className='text-blue_bar'>SAVE 20%</span> WITH AN ANNUAL SUBSCRIPTION`,
		// subtitle: `Or get started with a monthly plan`,

		image_players: {
			src: '/assets/images/baseball_players.png',
			width: 1463,
			height: 719,
			label: 'YES',
		},
		list: [
			'Personalized Highlight Reels',
			'Available in Spanish',
			'Postgame Recaps & Interviews',
			'Exclusive Shows & Podcasts',
			'Scores Delivered on Lock Screen',
			'And so Much More!',
		],
	},

	rewards: {
		title: '',
		// title: `<span className='text-blue_bar'>SAVE 20%</span> WITH AN ANNUAL SUBSCRIPTION`,
		// subtitle: `Or get started with a monthly plan`,
		subtitle: '',

		image_app: {
			src: '/assets/images/rewards/app.png',
			width: 779,
			height: 565,
		},

		image_fan: {
			src: '/assets/images/rewards/fan.png',
			width: 906,
			height: 820,
		},
	},

	watchplay: {
		title: '',
		// title: `<span className='text-blue_bar'>SAVE 20%</span> WITH AN ANNUAL SUBSCRIPTION`,
		subtitle: `Make gameday more fun by making picks before, or play along during games where you can win BIG cash prizes.`,

		image_app: {
			src: '/assets/images/watchplay/app.png',
			width: 779,
			height: 565,
		},

		image_fan: {
			src: '/assets/images/watchplay/phone.png',
			width: 1072,
			height: 535,
		},
	},

	packages: {
		title: '',
		// title: `<span className='text-blue_bar'>SAVE 20%</span> WITH AN ANNUAL SUBSCRIPTION`,
		// subtitle: `Or get started with a monthly plan`,
		subtitle: '',
		offerings: [
			{
				id: 'annual',
				title: 'ANNUAL SUBSCRIPTION',
				price: '$239.99',
				__price_sale: '$239.99',
				price_sale: '$199.99',
				saleTagline: 'BEST VALUE',
			},
			{ id: 'monthly', title: 'MONTHLY SUBSCRIPTION	', price: '$24.99', price_sale: '$19.99' },
		],
		//blurb: '*OFFER EXPIRES APRIL 30, 2024',
		terms: 'INTRODUCTORY OFFER. SUBJECT TO TERRITORIAL RESTRICTIONS.',
		cta: {
			id: 'subscribe',
			label: 'SUBSCRIBE NOW',
		},
	},

	features: {
		title: `IS <span className='text-blue_bar'>MORE</span>`,
		items: [
			{
				id: 'stream',
				title: `MORE WAYS TO <span class='text-blue_bar'>WATCH</span>`,
				blurb: 'Enhance your viewing experience with live stats.',
				img: {
					src: '/assets/images/devices/tv.png',
					width: 779,
					height: 565,
				},
				icon: {
					src: '/assets/images/icon_stream.svg',
					width: 0,
					height: 0,
				},
			},
			{
				id: 'interact',
				title: `MORE WAYS TO <span class='text-blue_bar'>INTERACT</span>`,
				blurb: 'Watch live games with friends.',
				img: {
					src: '/assets/images/devices/tablet.png',
					width: 739,
					height: 536,
				},
				icon: {
					src: '/assets/images/icon_interact.svg',
					width: 381,
					height: 751,
				},
			},
			{
				id: 'win',
				title: `MORE WAYS TO <span class='text-blue_bar'>WIN</span>`,
				blurb: 'Put some skin in the game - make big picks to win big prizes.',
				img: {
					src: '/assets/images/devices/phone.png',
					width: 381,
					height: 751,
				},
				icon: {
					src: '/assets/images/icon_win.svg',
					width: 0,
					height: 0,
				},
			},
		],
		cta: {
			id: 'subscribe',
			label: 'SUBSCRIBE NOW',
		},
	},

	watch: {
		title: `WATCH ON ALL YOUR <span className='text-blue_bar'>FAVORITE DEVICES</span>`,
		title_m: `WATCH ON ALL YOUR<br /><span className='text-blue_bar'>FAVORITE DEVICES</span>`,
		icon: {
			src: '/assets/images/icon_watch_devices.svg',
			width: 0,
			height: 0,
			label: 'Watch on all your devices',
		},
		logos: [
			// { id: 'androidtv', src: '/assets/images/platforms/androidtv.jpg', width: 516, height: 401, label: 'AndroidTV' },
			{ id: 'googletv', src: '/assets/images/platforms/googletv.jpg', width: 516, height: 401, label: 'GoogleTV' },
			{ id: 'android', src: '/assets/images/platforms/android.jpg', width: 516, height: 401, label: 'Android' },
			{ id: 'androidtv', src: '/assets/images/platforms/androidtv.jpg', width: 516, height: 401, label: 'AndroidTV' },
			{ id: 'ios', src: '/assets/images/platforms/ios.jpg', width: 516, height: 401, label: 'iOS' },
			{ id: 'atv', src: '/assets/images/platforms/appletv.jpg', width: 516, height: 401, label: 'AppleTV' },
			{ id: 'firetv', src: '/assets/images/platforms/firetv.jpg', width: 516, height: 401, label: 'FireTV' },
			{ id: 'roku', src: '/assets/images/platforms/roku.jpg', width: 516, height: 401, label: 'Roku' },
			{ id: 'samsung', src: '/assets/images/platforms/samsung.jpg', width: 516, height: 401, label: 'SamsungTV' },
			{ id: 'vizio', src: '/assets/images/platforms/vizio.png', width: 516, height: 401, label: 'Vizio' },
			{ id: 'lg', src: '/assets/images/platforms/lg.png', width: 516, height: 401, label: 'LG' },
		],
	},

	faq: {
		cta: {
			id: 'subscribe',
			label: 'SUBSCRIBE NOW',
		},
		title: `FREQUENTLY ASKED QUESTIONS`,
		questions: [
			{
				question: 'What is the YES App?',
				answer: "The YES App is the hub for all of the YES Network’s programming, including live Yankees and Nets games, and other premium shows and series. The YES App has exclusive features such as live stats overlays, free predictor games, a rewards program and more. All fans who live in the YES Network’s regional coverage territory (NY, CT, North and Central NJ and Northeastern PA – see below for more details on the YES Network’s regional coverage territory) can access all of YES' programming and features via the YES App using their TV provider login credentials or through a subscription purchased directly from YES.",
			},
			{
				question: 'How can I access the YES App?',
				answer: "You may already have access to all of the great content and features on the YES App through your subscription to the YES Network (available on cable, telco, satellite and digital providers in YES' regional coverage territory). Just download the YES App, select the option to log-in with your TV provider and input your TV provider log-in credentials. If you don't have a subscription to the YES network through a TV provider, you can purchase a subscription to the YES App on <a class='_link !font-medium !text-xl text-primary' href='https://watchyesnetwork.com' target='_blank' rel='noopener'>www.watchyesnetwork.com</a>.",
			},
			{
				question: 'How do I know if I already have access to the YES App through my TV Provider?',
				answer: `You should check with your TV provider to see if the YES Network is included in your package. If your TV subscription includes the YES Network and you live within the YES Network's regional coverage territory, then you already have access to the YES App.`,
			},
			{
				question: "I don't have a TV subscription. How can I purchase a subscription to the YES App directly?",
				answer: `Subscriptions to the YES App are available today! You can purchase either an annual or monthly subscription. Get in early and subscribe BEFORE APRIL 30, 2024 and save big! Just go to <a class='_link !font-medium !text-xl text-primary' href='https://watchyesnetwork.com' target='_blank' rel='noopener'>www.watchyesnetwork.com</a>.`,
			},
			{
				question: 'How much do the annual and monthly subscriptions cost?',
				answer: `An annual subscription is $239.99/year and a monthly subscription is $24.99/month.`,
			},
			{
				question: 'What programming can I watch on the YES App?',
				answer: `With the YES App, you can stream all live YES Network programming, including Yankees, Nets and pre- and post-game shows. You will also be able to watch all of YES' on-demand programming, including game replays and premium original content, as well as YES App-exclusive series.`,
			},
			{
				question: 'Will I get all the Yankees and Nets games on the YES App?',
				answer: `With the YES App, you will get access to all Yankees and Nets games that are televised by the YES Network.`,
			},
			{
				question: 'How is the YES App different from the YES Network?',
				answer: `The YES App is the same whether you receive access through your TV provider or if you purchase an annual or monthly subscription through <a class='_link !font-medium !text-xl text-primary' href='https://watchyesnetwork.com' target='_blank' rel='noopener'>www.watchyesnetwork.com</a>. If you already have access to the YES Network via your TV provider, nothing will change for you.`,
			},
			{
				question: 'On which devices can I access content on the YES App?',
				answer: `On mobile/tablet, you can download the YES App from the App Store or Google Play Store. On the web, go to <a class='_link !font-medium !text-xl text-primary' href='https://watchyesnetwork.com' target='_blank' rel='noopener'>www.watchyesnetwork.com</a>. For connected TV devices, you can access the YES App on Android, AndroidTV, Apple TV, iOS, Google TV, Roku, Amazon Fire TV, Samsung Smart TV, Vizio, and LG.. We are continuously working to try to add additional devices to our offerings with the YES App and encourage you to keep checking back.`,
			},
			{
				question:
					"Do I need to live in YES' regional viewing territory to be able to purchase a subscription to the YES App?",
				answer: `Anyone in the U.S. can download the YES App; however, to purchase a subscription to the YES App, your location services must determine that you are located within YES' regional viewing territory, and the primary address associated with your credit card must also be located within YES' home territory.`,
			},
			{
				question: "Where can I watch the YES App's content?",
				answer: `If you are located in YES' regional viewing area, you have access to all of the YES App's content 24/7. In addition, you can travel anywhere in the U.S. and receive access to the YES App for up to 30 days.`,
			},
			{
				question: 'Can I cancel my YES App subscription at any time?',
				answer: `If you no longer want access to the YES App 24/7, you can cancel your subscription at any time. When you cancel your monthly subscription, your access will run out at the end of the month in which you canceled. When you cancel your annual subscription, your access will run out a year from the date you initially subscribed.`,
			},
		],
		footer: {
			title: 'More questions?',
			titleLink: { id: 'helpCenter', label: 'Visit our Help Center' },
			links: [
				//{ id: 'helpCenter', label: 'Vist our Help Center' },
				{ id: 'privacy', label: 'Privacy Policy' },
				{ id: 'terms', label: 'Terms of Service' },
			],
		},
	},
};

export default data;
