
import { appWithTranslation } from 'next-i18next';
import '@/i18n';
import '@/styles/main.css';
import type { AppProps } from 'next/app';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import Layout from '@components/Layout';

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<GTMProvider
			state={{
				id: process.env.NEXT_PUBLIC_GTM_ID,
				dataLayer: {
					event: 'Site Loaded',
				},
			}}
		>
			<Layout>
				<Component {...pageProps} />
			</Layout>
		</GTMProvider>
	);
}

export default appWithTranslation(MyApp);

