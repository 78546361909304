import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './public/locales/en/common.json';
import translationES from './public/locales/es/common.json';

const resources = {
	en: {
		translation: translationEN,
	},
	es: {
		translation: translationES,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: 'en',
	keySeparator: false, // we do not use keys in form messages.welcome
	debug: true,
	detection: {
		// order: ['queryString', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
		order: ['path'],
		caches: ['cookie'],
	},
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

export default i18n;
